import { toast } from 'react-toastify';
import { Api } from '../axios-config';

interface IAuth {
  token: string;
}

const auth = async (UserName: string, Password: string): Promise<IAuth | Error> => {
  const toastId = toast.loading('Carregando...'); // Inicia o toast de loading
  try {
    const { data } = await Api.post('/auth/login', { UserName, Password } );

    if (data) {
      // Atualiza o toast de loading para sucesso
      toast.update(toastId, {
        render: 'Login realizado com sucesso!',
        type: 'success',
        isLoading: false,
        autoClose: 1500, // Fecha automaticamente após 3 segundos
      });
      return data;
    }

    return new Error('Erro no login.');
  } catch (error: any) {
    if (error.response) {
      // Captura a mensagem enviada pelo backend
      // Atualiza o toast de loading para erro
      toast.update(toastId, {
        render: error.response?.data?.message || 'Erro no login. Tente novamente.',
        type: 'error',
        isLoading: false,
        autoClose: 5000, // Fecha automaticamente após 5 segundos
      });


      // Retorna um erro com a mensagem do backend
      return new Error(error.response?.data?.message || 'Erro no login.');
    } else if (error.request) {

      toast.update(toastId, {
        render: 'Erro no login. Tente novamente ou reporte a TI',
        type: 'error',
        isLoading: false,
        autoClose: 5000, // Fecha automaticamente após 5 segundos
      });

      // Erro de conexão com o servidor
      console.error('Erro na requisição:', error.request);
      return new Error('Erro no login: falha ao se conectar com o servidor.');
    } else {
      toast.update(toastId, {
        render: 'Erro no login. Tente novamente ou reporte a TI',
        type: 'error',
        isLoading: false,
        autoClose: 5000, // Fecha automaticamente após 5 segundos
      });
      // Outros tipos de erros
      console.error('Erro desconhecido:', error.message);
      return new Error(`Erro no login: ${error.message || 'Erro desconhecido.'}`);
    }
  }
};

export const AuthService = {
  auth,
};
