// RAccordion.tsx

import {
  Box,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Divider,
  Grid2,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  Controller,
  Control,
  FieldArrayWithId,
  FieldValues,
} from 'react-hook-form';
import { useState } from 'react';

type TAccordionProps<TFieldValues extends FieldValues, TFieldConfig> = {
  fields: FieldArrayWithId<TFieldValues, any, 'id'>[];
  control: Control<any>;
  onAdd: () => void;
  onRemove: (index: number) => void;
  name: string;
  groupLabel: string;
  fieldConfig?: {
    id: keyof TFieldConfig; // Chave do campo
    label: string; // Rótulo a ser exibido
    type?: string; // Tipo do campo
    component?: React.FC<any>; // Componente customizado
    extraProps?: Record<string, any>;
  }[];
  buttonLabel?: string;
  alwaysOpen?: boolean; // ✅ Propriedade para manter um Accordion sempre aberto
};

export const RAccordion = <
  TFieldValues extends FieldValues,
  TFieldConfig extends FieldValues
>({
    fields,
    control,
    onAdd,
    onRemove,
    name,
    groupLabel,
    fieldConfig,
    buttonLabel,
    alwaysOpen = false, // ✅ Padrão: Accordion pode ser fechado
  }: TAccordionProps<TFieldValues, TFieldConfig>) => {
  
 
  // ✅ Estado para armazenar o Accordion aberto
  const [expanded, setExpanded] = useState<string | false>(
    alwaysOpen ? name : false // Se `alwaysOpen` for true, mantém aberto
  );

  // ✅ Função para abrir/fechar o Accordion
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (alwaysOpen) return; // ✅ Se for `alwaysOpen`, impede o fechamento
      setExpanded(isExpanded ? panel : false);
    };
  
  return (
    <Accordion expanded={expanded === name} onChange={handleChange(name)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{groupLabel}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {fields.map((field, index) => (
          <Grid2 key={field.id} mb={2}>
            <Grid2 container direction="row" spacing={2}>
              {fieldConfig?.map((config) => (
                <Grid2
                  key={config.id as string}
                  sx={{
                    flexBasis: {
                      xs: '80%',
                      sm: '80%',
                      md: '50%',
                      lg: '33%',
                      xl: '25%',
                    },
                  }}
                >
                  <Controller
                    name={`${name}.${index}.${String(config.id)}` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      if (config.component) {
                        // Renderiza o componente customizado, como RAutoComplete
                        const Component = config.component;
                        return (
                          <Component
                            {...field} // Props básicas do Controller
                            control={control} // Passa o control do React Hook Form
                            label={config.label} // Passa o label configurado
                            error={!!error} // Verifica se há erro
                            helperText={error?.message || ''} // Mensagem de erro
                            {...(config.extraProps || {})} // Passa propriedades adicionais, como source
                          />
                        );
                      }

                      // Renderização padrão com TextField
                      return (
                        <TextField
                          {...field}
                          label={config.label}
                          type={config.type || 'text'}
                          fullWidth
                          error={!!error}
                          helperText={error?.message || ''}
                        />
                      );
                    }}
                  />
                </Grid2>
              ))}
              <IconButton  onClick={() => onRemove(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid2>
            <Divider sx={{ marginBottom: 4 }} />
          </Grid2>
        ))}
        <Button variant="outlined" startIcon={<AddIcon />} onClick={onAdd}>
          {buttonLabel}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
