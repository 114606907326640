// RSelect

import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type FormFields = {
  CaracteristicaImovel: number | null;
  CodigoStatus: string;
  Telefones: { Tipo: string | null }[];
};

type TRSelectProps = {
  name: keyof FormFields | `Telefones.${number}.Tipo`; // Nome do campo
  label: string; // Rótulo do Select
  options: { value: string | number; label: string }[]; // Opções disponíveis no Select
  disabledValue?: boolean; // Define se o Select está desativado
};

export const RSelect: React.FC<TRSelectProps> = ({
  name,
  label,
  options,
  disabledValue = false,
}) => {
  const { control } = useFormContext<FormFields>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          sx={{ minWidth: { xs: '100%'/*, sm: 250*/ } }} // Ajuste para responsividade
          error={!!fieldState.error} // Verifica se há erros no campo
        >
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            disabled={disabledValue}
            value={
              field.value !== null && field.value !== undefined
                ? String(field.value) // Converte o valor para string
                : '' // Usa string vazia para valores nulos ou indefinidos
            }
            label={label}
            onChange={(e) => {
              const value = e.target.value;
              field.onChange(value === '' ? null : value); // Converte valores vazios para null
            }}
          >
            <MenuItem value="">
              <em>Selecione</em> {/* Opção padrão para valores nulos */}
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={String(option.value)}
                value={String(option.value)} // Usa String para padronizar
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};
