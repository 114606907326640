// import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const getAll = async () => {
  try {
    const urlRelativa = 'https://api.conabbombas.com.br/cities';
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return {
        data
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error('ERROR AO OBTER CIDADES', error);
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

const getCityCodeByIBGECode = async (ibgeCode: string): Promise<any | null> => {
  try {
    const urlRelativa = `https://api.conabbombas.com.br/cities/${ibgeCode}`;
    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data; // Retorna apenas os dados da cidade
    }

    throw new Error('Erro ao listar o registro.'); // Lançando erro corretamente
  } catch (error) {
    console.error('Erro ao obter cidade por código do IBGE', error);
    return null; // Retorna null ao invés de um objeto Error
  }
};


export const CitiesService = {
  getAll,
  getCityCodeByIBGECode
};
