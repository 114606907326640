import axios from 'axios';

import { responseInterceptor, errorInterceptor } from './interceptors';
import { Environment } from '../../../environment';


const Api = axios.create({
  baseURL: Environment.URL_BASE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor para incluir o token atualizado do localStorage em cada requisição
Api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('APP_ACCESS_TOKEN');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
);

export { Api };
