// RAutoComplete

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { RegioesService } from '../services/api/regiao/RegioesService';
import { CategoriasService } from '../services/api/categoria/CategoriasService';
import { EntitiesService } from '../services/api/entities/EntitiesService';
import { TypeServOrdService } from '../services/api/typeServOrdService/TypeServOrdService';
import { TypeAssistancesService } from '../services/api/typeAssistancesService/TypeAssistancesService';
import { ProductService } from '../services/api/productService/ProductService';
import { CitiesService } from '../services/api/cities/CitiesService';
import { StreetTypesService } from '../services/api/streetType/StreetTypeService';


import { useDebounce } from '../hooks';
import { Controller, Control } from 'react-hook-form';

// Mapa de serviços
const serviceMap: Record<string, any> = {
  RegioesService,
  CategoriasService,
  EntitiesService,
  TypeServOrdService,  // Adicione outros serviços conforme necessário
  ProductService,
  TypeAssistancesService,
  CitiesService,
  StreetTypesService
};


type TAutoCompleteOption = {
  id: string;
  label: string;
};

interface IAutoCompleteRegiaoProps {
  isExternalLoading?: boolean;
  control: Control<any>; // Tipagem mais precisa do react-hook-form
  name: string;
  label: string;
  source: 'RegioesService' | 'CategoriasService' | 'EntitiesService' | 'TypeServOrdService' | 'ProductService' | 'TypeAssistancesService' | 'CitiesService' | 'StreetTypesService'; // Adicione outras fontes conforme necessário
  disabledValue?: boolean
}

export const RAutoComplete: React.FC<IAutoCompleteRegiaoProps> = ({
  isExternalLoading = false,
  control,
  name,
  label,
  source,
  disabledValue
}) => {
  const { debounce } = useDebounce();
  const [options, setOptions] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const getServiceBySource = (source: string) => {
    return serviceMap[source] || null;
  };

  // 🔵 Carregando as opções dinamicamente
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const fieldService = getServiceBySource(source);

      try {
        const result = await fieldService.getAll(1, search);
        if (result instanceof Error) {
          console.error(result.message);
        } else {
          setOptions(
            result.data.map((item: any) => ({
              id: item.Codigo || item.city_cod,
              label: item.Codigo && item.Nome
                ? `${item.Codigo} - ${item.Nome}`
                : `${item.full_name} - ${item.acronym_federal_unit}`,
            }))
          );
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(fetchData);
  }, [search, source, debounce]);

  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={null}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // 🟢 Ajuste para considerar `value` como `id` ou objeto `{ id, label }`
        const selectedOption =
          options.find((option) => option.id === value) || // Busca o objeto na lista de opções
          (value ? { id: value, label: value } : null); // Fallback para exibir apenas o `id` //TODO: Deixar com o simbolo de carregando, em vez de mostrar o ID enquanto busca os options

        return (
          <Autocomplete
            disabled={disabledValue}
            value={selectedOption} // Garante que o valor seja exibido corretamente
            options={options}
            loading={isLoading || isExternalLoading}
            onInputChange={(_, newInputValue) => setSearch(newInputValue)} // Atualiza a busca
            onChange={(_, newValue) => onChange(newValue?.id || '')} // Armazena apenas o ID no formulário
            ListboxProps={{ style: { maxHeight: 200 } }}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        );
      }}
    />
  );
};
