import { Avatar, Divider, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Box } from '@mui/system';
import { useAppThemeContext, useAuthContext, useDrawerContext } from '../../contexts';
import { useEffect, useState } from 'react';
import { installPWA, isPWAInstallable, initInstallPrompt } from '../../../utils/pwaInstall';

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}
const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });


  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

interface IMenuLateralProps {
  children: React.ReactNode;
}
export const MenuLateral: React.FC<IMenuLateralProps> = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();
  const { toggleTheme } = useAppThemeContext();
  const { logout } = useAuthContext();

  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    // Inicializa o evento 'beforeinstallprompt'
    initInstallPrompt();

    const handleBeforeInstallPrompt = () => {
      setIsInstallable(isPWAInstallable());
    };

    const handleAppInstalled = () => {
      console.log('PWA foi instalado.');
      setIsInstallable(false); // Atualiza o estado para remover o botão
    };

    // Adiciona os listeners
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    // Remove os listeners ao desmontar o componente
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  return (
    <>
      <Drawer open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
        <Box width={theme.spacing(28)} height="100%" display="flex" flexDirection="column">

          <Box
            width="100%"
            height={theme.spacing(20)} // Altura total do contêiner
            display="flex"
            flexDirection="column" // Alinha os itens verticalmente
            alignItems="center" // Centraliza horizontalmente
            justifyContent="center" // Centraliza verticalmente
          >
            {/* Avatar */}
            <Avatar
              sx={{
                height: theme.spacing(8), // Tamanho do Avatar
                width: theme.spacing(20),
                marginBottom: theme.spacing(1), // Espaço pequeno abaixo do Avatar
              }}
              src="https://ideogram.ai/assets/image/lossless/response/ktaEdkxCS-WbSUlu3lulxA"
            />

            {/* Imagem abaixo do Avatar */}
            <Box
              component="img"
              sx={{
                width: smDown ? '50%' : '40%', // Largura responsiva
                maxWidth: '100px', // Tamanho máximo
                height: 'auto', // Mantém a proporção
                borderRadius: '8px', // Bordas arredondadas
                marginTop: 0, // Remove espaço extra acima
              }}
              src="https://riosoft.com.br/wp-content/uploads/2024/01/Riosoft-Logo-512x512-1.png"
            />
          </Box>

         
          <Divider />

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map(drawerOption => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          </Box>

          <Box>
            <List component="nav">
              {isInstallable && (
                <ListItemButton onClick={installPWA}>
                  <ListItemIcon>
                    <Icon>get_app</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Instalar App" />
                </ListItemButton>
              )}
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary="Alternar tema" />
              </ListItemButton>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <Icon>logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Drawer>

      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  );
};
