// Este arquivo contém funções relacionadas à instalação do PWA (Progressive Web App)

// initInstallPrompt: Configura um listener para capturar o evento 'beforeinstallprompt'
// que é disparado quando o PWA pode ser instalado. O evento é armazenado para uso posterior.

// installPWA: Função assíncrona que mostra o prompt de instalação do PWA ao usuário
// quando chamada. Ela utiliza o evento armazenado anteriormente e registra se o usuário
// aceitou ou recusou a instalação.

// isPWAInstallable: Função que verifica se o PWA pode ser instalado no momento,
// retornando true se houver um prompt de instalação disponível (deferredPrompt existe)
// e false caso contrário.

// A declaração global WindowEventMap adiciona tipagem TypeScript para o evento
// beforeinstallprompt, garantindo suporte adequado do TypeScript para este evento
// específico do PWA.

let deferredPrompt: BeforeInstallPromptEvent | null = null;

export const initInstallPrompt = (): void => {
  window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
    e.preventDefault(); // Previne o comportamento padrão
    deferredPrompt = e; // Armazena o evento
    console.log('Evento beforeinstallprompt capturado.');
  });
};

export const installPWA = async (): Promise<void> => {
  if (!deferredPrompt) {
    console.warn('Prompt de instalação não disponível.');
    return;
  }

  try {
    // Mostra o prompt de instalação
    await deferredPrompt.prompt();

    // Espera pela escolha do usuário
    const { outcome } = await deferredPrompt.userChoice;
    console.log(`Usuário ${outcome === 'accepted' ? 'aceitou' : 'recusou'} a instalação.`);
  } catch (error) {
    console.error('Erro ao exibir o prompt de instalação:', error);
  } finally {
    // Limpa o evento armazenado
    deferredPrompt = null;
  }
};

export const isPWAInstallable = (): boolean => {
  return !!deferredPrompt;
};

// Tipagem para BeforeInstallPromptEvent
declare global {
  interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>;
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
  }

  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}
