// RRadio.tsx

import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@mui/material';
import { Controller, Control } from 'react-hook-form';

// Tipagem para as opções
type Option = {
  label: string; // Texto a ser exibido
  value: string; // Valor associado
};


type TRadioProps = {
    control: Control<any>,
    name: string;
    label: string;
    defaultValue: string
    options: Option[];
}

export const RRadio = ({ control, name, label, defaultValue, options }: TRadioProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue} // Valor padrão
      render={({ field }) => (
        <FormControl>
          {/* Tipo */}
          <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            {...field} // Vincula o campo ao Controller
            value={field.value} // Garante que o valor esteja sincronizado
            onChange={(e) => field.onChange(e.target.value)} // Atualiza o valor corretamente
          >

            {/* Geração dinâmica das opções */}
            {
              options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
