import { useEffect, useMemo, useState } from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Box,
  useMediaQuery, // Import para detectar o tamanho da tela
  useTheme,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import {
  IListagemPessoa,
  EntitiesService,
} from '../../shared/services/api/entities/EntitiesService';
import { FerramentasDaListagem } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useDebounce } from '../../shared/hooks';
import { Environment } from '../../shared/environment';

export const EntitiesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Detecta se é desktop

  const [rows, setRows] = useState<IListagemPessoa[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(2);

  const busca = useMemo(() => searchParams.get('busca') || '', [searchParams]);

  const pagina = useMemo(() => Number(searchParams.get('pagina') || '1'), [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      EntitiesService.getAll(pagina, busca).then((result) => {
        setIsLoading(false);
        console.log('result', result);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
    });
  }, [busca, pagina]);

  return (
    <LayoutBaseDePagina
      titulo='Entidades Prospects'
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={busca}
          textoBotaoNovo='Nova'
          aoClicarEmNovo={() => navigate('/entidades/detalhe/nova')}
          aoMudarTextoDeBusca={(texto) =>
            setSearchParams({ busca: texto, pagina: '1' }, { replace: true })
          }
        />
      }
    >
      <TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ação</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Data Cadastro</TableCell>
              <TableCell>Nome</TableCell>
              {isDesktop && (
                <>
                  <TableCell>CNPJ/CPF</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tipo</TableCell>
                  {/*<TableCell>Tel Comercial</TableCell>
                  <TableCell>E-mail Comercial</TableCell>*/}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Codigo}>
                <TableCell>
                  <IconButton
                    size='small'
                    onClick={() => navigate(`/entidades/detalhe/${row.Codigo}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.Codigo}</TableCell>
                <TableCell>{moment(row.DataCadastro).format('DD/MM/YY')}</TableCell>
                <TableCell  sx={{
                  maxWidth: isDesktop ? 200 : 120, // Define largura fixa também no mobile
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>{row.Nome}</TableCell>
                {isDesktop && (
                  <>
                    <TableCell>{row.CPFCNPJ}</TableCell>
                    <TableCell>{row.StatusEntidade}</TableCell>
                    <TableCell>{row.Tipo}</TableCell>
                    {/*<TableCell>{row.Tipo}</TableCell>
                    <TableCell>{row.Tipo}</TableCell>*/}
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={isDesktop ? 7 : 4}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}

            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={isDesktop ? 7 : 4}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
