import { AxiosError } from 'axios';

export const errorInterceptor = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.status === 401) {
    console.warn('Token inválido ou expirado. Redirecionando para login...');

    // Remover o token do armazenamento local
    localStorage.removeItem('APP_ACCESS_TOKEN');

    // Redirecionar para a página de login
    window.location.href = '/login';

    return Promise.reject(new Error('Sessão expirada. Faça login novamente.'));
  }

  return Promise.reject(error);
};
