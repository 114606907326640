// import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const getAddressByZipCode = async (zipcode: string): Promise<any | null> => {
  try {
    const urlRelativa = `https://api.conabbombas.com.br/api/address/${zipcode}`;
    const { data } = await Api.get(urlRelativa);

    if (data) {
      return data; // Retorna apenas os dados do endereço
    }

    throw new Error('Erro ao listar o registro.'); // Lançando erro corretamente
  } catch (error) {
    console.error('Erro ao obter endereço por CEP', error);
    return null; // Retorna null ao invés de um objeto Error
  }
};

export const AddressService = {
  getAddressByZipCode
};
