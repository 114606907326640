import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToastContainer } from 'react-toastify';

import './shared/forms/TraducoesYup';

import { AppThemeProvider, AuthProvider, DrawerProvider } from './shared/contexts';
import { Login, MenuLateral } from './shared/components';
import { AppRoutes } from './routes';

/*
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registrado com sucesso:', registration);
      })
      .catch((error) => {
        console.error('Falha ao registrar o Service Worker:', error);
      });
  });
}*/


export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <ToastContainer /> {/* Contêiner global para gerenciar os toasts */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Login>

            <DrawerProvider>
              <BrowserRouter>

                <MenuLateral>
                  <AppRoutes />
                </MenuLateral>

              </BrowserRouter>
            </DrawerProvider>

          </Login>
        </LocalizationProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
};
